@use "../../variables";
@use "../../mixins";

.checkmark {
    position: relative; /* TODO: Refacto, ei ole vaja absolute'i (radio_button.scss) */
}


.radio {
    padding-left: 0; /* TODO: Refacto */
}

.icon-additional-choice {
    padding: 0 6px;
    width: 24px;
    height: 24px;
}

.cart-item {
    margin: 16px 0;

    &__producer {
        padding-top: 10px;
    }

    .accordion__item {
        background: variables.$white;
        margin: 8px;
        padding: 8px;
        border: 1px solid variables.$neutral-lighter;
        border-radius: 8px;

        .cart-accordion__content {
            background: variables.$neutral-lighter-25;
            margin: 8px -8px -8px -8px;
            border-radius: 0 0 8px 8px;
            padding: 8px;
        }
    }

    .product.additional {
        display: grid;
        position: relative;
        grid-template-columns: 1fr 4fr;
        align-items: center;
    }

    .price {

        &__old {
            color: variables.$neutral;
            text-decoration: line-through;
        }

        &__em {
            font-weight: 700;
        }
    }

    .energy-container {
        display: flex;
        align-items: center;

        :last-child {
            margin-left: auto;
        }
        
        .rating {
            width: 62px;
        }
    }

    .list__item {
        &.service {
            display: grid;
            column-gap: 8px;

            .radio, .checkbox {
                display: grid;
                column-gap: 8px;
                align-items: center;
            }
        }
    }

    .freegift {
        align-items: center;
        display: flex;
        flex-direction: column;
        text-align: center;

        .icon-gift {
            width: 32px;
            color: variables.$supporting-03;
        }
    }

    .qty-plus-minus {
        &--ro {
            color: variables.$neutral;
        }

        & {
            display: flex;
            align-items: center;
            color: variables.$neutral;
            border: 1px solid variables.$neutral-lighter;
            border-radius: 25px;
            height: 36px;
        }
        
        input {
            border: none;
            width: 38px; /* 9999 */
            text-align: center;
            margin: -8px;
        }
    }

    .service-priceqty {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-content: center;
    }

    .remove-item .button__text {
        font-size: variables.$font-size-sm;
    }

    .qty {
        text-align: center;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

/* wide */
@media (min-width: variables.$responsive-break) {
    .cart-list {
        position: relative;
        margin: 16px;

        > li {
            border-bottom: solid 1px variables.$neutral-lighter-100;

            &:last-of-type {
                border-bottom: 0;
            }
        }

        .vertical-line {
            border-right: solid 1px variables.$neutral-lighter-100;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 160px;
        }
    }

    .cart-item {

        &__title, &.campaign-title {
            font-size: variables.$font-size-md;
            grid-template-columns: auto;
        }

        .desc [data-product-code] {
            font-weight: variables.$font-weight-bold;
        }


        &__image {
            width: 90px;
            height: 90px;

        }

        .price {
            text-align: right;

            &__em {
                font-size: 20px;

                &.discount {
                    color: variables.$supporting-01;
                }
            }
        }
        
        .energy-container {
            text-align: right;
            flex-direction: column-reverse;
            
            .rating {
                padding-left: 8px;
            }
        }

        .product {
            grid-row: 1 / span 2;
            text-align: center;
        }

        .long-desc {
            grid-column: 2 / span 2;
            grid-row-start: 2;
            padding: 0 16px;
        }

        .list__item {
            &.service {
                &:not(.two-col){
                    grid-template-columns: 500px 50px 145px;
                }
                &.two-col{
                    grid-template-columns: 500px 150px;
                }
                margin: 8px 0;
            }

            .radio, .checkbox {
                grid-template-columns: 40px 300px;

                .package-price {
                    color: variables.$neutral;
                }
            }
        }

        .list__sub {
            padding-left: 40px;
        }
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        /* Firefox */
        input[type=number] {
            -moz-appearance: textfield;
        }

        .fa-minus, .fa-plus {
            color: variables.$primary;
            cursor: pointer;
        }
    }

    .accessories-carousel {
        @include mixins.carousel-buttons;

        @include mixins.tns-nav;

        .carousel-card {

            &:hover {
                border-color: variables.$primary-light;

                img {
                    transform: scale(1.1);
                }

                .carousel-card__title {
                    color: variables.$primary-dark;
                }
            }
        }

        &__controls {
            top: 45%;
        }

        &__wrapper {
            position: relative;
            max-width: 800px;
        }
    }
    
    .additional-container {
        padding-top: 16px;
        
        .campaign-title {
            background: linear-gradient(to left, variables.$white 160px, variables.$neutral-lighter-25 160px);
            margin-top: 0;
            padding: 16px 165px 16px 30px;
            margin-left: 180px;
        }

        .campaign-terms {
            padding: 16px 165px 16px 30px;
            margin-left: 180px;
        }
        
        .cart-item {
            &:not(.campaign-title) {
                padding-left: 180px;
            }
            
            .product:not(.additional) {
                padding-right: 5px;
                text-align: center;
            }
            
            &__separator {
                border-bottom: variables.$neutral-lighter-100 solid 1px;
                margin-right: 160px;
            }
        }
    }

    .additional-label {
        cursor: pointer;
    }

    .warranty-serial {
        > * {
            margin-top: 8px;
        }

        input {
            max-width: 350px;
        }

        .checkbox {
            max-width: 330px;
        }
    }
}
/* narrow */
@media (max-width: variables.$responsive-break - 1) {
    .cart-item__producer .label,
    .cart-item__price .price {
        display: none;
    }

    .cart-item {
        display: grid;
        grid-template-columns: 3fr 2fr 2fr;
        align-items: center;
        border: 1px solid variables.$neutral-lighter;
        border-radius: 8px;
        margin: 16px;
        padding: 8px;

        &.campaign-title {
            grid-template-columns: auto;
            border: none;
        }

        .product {
            grid-row: 1 / span 2;
            grid-column: 1;
            padding: 8px;
            text-align: center;

            img {
                max-width: 100%
            }
        }

        .desc {
            grid-column: 2 / span 2;

            .cart-item__title {
                color: variables.$black;
            }

            [data-product-code] {
                color: variables.$neutral-lighter;
            }
        }

        .long-desc {
            grid-row: 4;
            grid-column: 1 / span 3;
            background: variables.$neutral-lighter-25;
            margin: 8px -8px -8px -8px;
            border-radius: 0 0 8px 8px;
        }

        .price {
            grid-row: 2;
            grid-column: 2;

            &__em {
                font-size: variables.$font-size-default;

                &.discount {
                    color: variables.$primary;
                }
            }
        }
        
        .energy-container {
            grid-row: 3;
            grid-column: 1/span 3;
        }

        .list__item {
            &.service {
                &:not(.two-col){
                    grid-template-columns: repeat(3, auto);
                }
                &.two-col{
                    grid-template-columns: repeat(2, auto);
                }
                margin: 8px 0;
                grid-gap: 8px;

                .checkbox, .radio {
                    grid-template-columns: 1fr 8fr;
                    height: auto;
                }
            }

            .package-price {
                color: variables.$primary;
                text-align: right;
            }

            .service-quantity {
                display: flex;
                flex-wrap: nowrap;
            }

            input:checked ~ .service-priceqty {
                font-weight: variables.$font-weight-bold;
            }
        }
    }
    
    .additional-container {
        .cart-item:not(.campaign-title) {
            grid-template-columns: 3fr 3fr 1fr;
        }
    }
}
