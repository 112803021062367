@use "../../variables";
@use "../../mixins";

.empty-cart {
    display: flex;
    flex-direction: column;
    background: variables.$neutral-lighter-25;
    padding: 0;
    height: 100%;;

    .title {
        font-weight: variables.$font-weight-bold;
        font-size: variables.$font-size-lg;
        color: variables.$primary;

        &--secondary {
            font-weight: variables.$font-weight-normal;
            font-size: variables.$font-size-lg;
            color: variables.$primary;
        }
    }

    .header {
        background: inherit;
        justify-content: start;
        align-items: center;
        flex-direction: column;
        padding: 48px 24px;
        color: variables.$primary;
        height: auto;
        border-top: 1px solid variables.$neutral-lighter-50;
        border-bottom: 1px solid variables.$neutral-lighter-50;
        z-index: auto;

        .image {
            @if variables.$site-nova {
                content: url('/images/novastar/empty_cart_nova.svg');
            } @else if variables.$site-avi {
                content: url('/images/avitela/empty_cart_avitela.svg');
            } @else {
                content: url('/images/empty_cart.svg');
            }
        }
    }

    .separator {
        border-bottom: 1px solid variables.$neutral-lighter-50;
        width: 80%;
        align-self: center;
        max-width: 680px;
    }

    .last-viewed-products {
        background: variables.$white;
        display: flex;
        flex-direction: column;
    }

    .promotions-container {
        display: flex;
        background: variables.$white;
        flex-direction: column;
        padding: 16px;

        .button {
            align-self: center;
            margin-top: 16px;
            padding: 12px 40px;
            border: 1px solid variables.$neutral-lighter-100;
        }
    }
}

/* desktop */
@media (min-width: variables.$responsive-break) {

    .empty-cart {
        .login-container, .login-description {
            display: none;
        }

        .promotions-container.desktop,
        .last-viewed-products.desktop {
            display: flex;
        }
    }
}

/* mobile */
@media (max-width: variables.$responsive-break - 1) {

    .empty-cart {
        .login-description {
            display: flex;
            justify-content: center;
            background: variables.$white;
            border-bottom: 1px solid variables.$neutral-lighter-100;
            padding: 48px 0;

            p {
                font-weight: variables.$font-weight-normal;
                font-size: variables.$font-size-lg;
                color: variables.$primary;
                text-align: center;
                width: 60%;
            }
        }

        .login-container {
            display: block;
            align-self: center;
            width: 100%;
            max-width: 680px;

            .tabs-menu {
                align-items: center;
                width: 100%;

                &__list {
                    padding: 0;
                    align-items: stretch;
                    flex-wrap: wrap;

                    &--primary {
                        width: 100%;
                        background: variables.$white;
                        display: flex;

                        .tabs-menu__item {
                            flex: 1 1 100px;
                        }

                        .tabs-menu__link {
                            color: variables.$primary;
                            font-weight: variables.$font-weight-normal;
                            font-size: variables.$font-size-default;
                            border-bottom: solid 4px transparent;
                            letter-spacing: 0.01em;

                            &--active {
                                font-weight: variables.$font-weight-bold;
                                border-bottom: solid 4px variables.$secondary;
                            }
                        }
                    }
                }

                &__item {
                    list-style: none;
                    display: inline-flex;
                    border-bottom: 0;

                    &:first-of-type {

                        .tabs-menu__link {
                            margin-left: 0;
                        }
                    }

                    &--active {
                        .tabs-menu__link {
                            font-weight: variables.$font-weight-bold;
                            color: variables.$primary;
                            border-bottom: solid 3px variables.$secondary;
                        }
                    }
                }

                &__link {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    text-align: center;
                    position: relative;


                    &:after {
                        content: attr(data-amount);
                        position: relative;
                        top: -10px;
                        font-size: variables.$font-size-xs;
                        font-weight: variables.$font-weight-bold;
                    }
                }

            }

            .accordion {
                @include mixins.accordion;
            }
        }

        .promotions-container.desktop,
        .last-viewed-products.desktop {
            display: none;
        }

        .last-viewed-products {
            .items-carousel__list {
                .items-carousel__item {
                    display: none;

                    &.tns-slide-active {
                        display: list-item;                        
                    }
                }
            }
        }
    }

    #saved-products {
        padding-bottom: 80px;
    }
}