@use "../../variables"; 
#shoppingCart:has(.empty-cart) {
    max-width: none;
    padding-bottom: 20px;
}

.button-checkout {
    display: flex;
    padding: 0 20px;
}
/* wide */
@media (min-width: variables.$responsive-break) {
    .cart-table__header, .cart-item {
        display: grid;
        grid-template-columns: 180px 1fr 200px 160px;
    }

    .cart-item__footer,
    .cart-item__header {

        .back-btn {
            font-weight: variables.$font-weight-bold;
            padding: 0;

            .button__text {
                font-size: variables.$font-size-default;
            }
        }
    }

    .button-checkout {
        margin-left: auto;
    }
}
/* narrow */
@media (max-width: variables.$responsive-break - 1) {
    .button-checkout {
        margin: 16px auto;
    }
}
