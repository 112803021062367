.icon-protection {
    content: url('/images/services/protection-idle.svg');
}
.icon-tools {
    content: url('/images/services/services-idle.svg');
}
.icon-trash {
    content: url('/images/trashcan.svg');
}
.icon-accessories {
    content: url('/images/accessories.svg');
}
