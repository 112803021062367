@use "../../variables";

.cart-item {
    &__total {
        margin-right: 32px;
    }

    &__wrapper {
        display: flex;
    }
}

/* wide */
@media (min-width: variables.$responsive-break) {
    .cart-item__header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 48px;
    }

    .cart-table {

        &__header {

            .qty {
                text-align: center;
            }

            .price {
                text-align: right;
            }

            color: variables.$neutral-dark;
            background: variables.$neutral-lighter-25;
            font-weight: variables.$font-weight-bold;
            padding: 12px 16px;
            margin: 24px 0;
        }
    }
    .cart-heading {
        display: none;
    }
}

/* narrow */
@media (max-width: variables.$responsive-break - 1) {
    .cart-table__header {
        display: none;
    }
    .cart-item {
        &__header {
            background: variables.$white;
            box-shadow: variables.$top-shadow;
            position: fixed;
            inset: auto 0 0 0;
            z-index: variables.$zindex-positive;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
        }

        &__total {
            display: flex;
            flex-direction: column;
        }

        &__wrapper {
            margin: 0 16px;
        }
    }

    h1 {
        font-size: variables.$font-size-md;
        font-weight: variables.$font-weight-normal;
    }
    .cart-heading {
        text-align: center;
        font-size: variables.$font-size-xl;
        margin: 16px;
    }
}
