@use "../../variables";

.cart-item__footer {
    display: flex;
    flex-direction: column-reverse;

    .price {
        color: variables.$neutral-darker;
        font-weight: variables.$font-weight-bold;
    }

    .voucher .accordion__item--active > .accordion__content {
        display: flex;
        gap: 15px;
    }
}

.terms__label {
    padding-left: 2.5rem;

    &.checkbox__label {
        width: auto;
    }
}

#toggle-voucher {
    &.button--tertiary {
        font-weight: 400;
        font-size: 16px;
        white-space: nowrap;
        cursor: pointer;
    }

    &.button--ghost {
        color: variables.$neutral-lighter;

        &:hover {
            color: variables.$primary-dark;
        }
    }
}

.checkout-shipping {
    &__container {
        display: flex;
        margin: 0 16px 30px 16px;
    }

    &__logo {
        stroke: variables.$neutral-light;
    }

    &__info {
        display: flex;
        flex-direction: column;
        border-left: 1px solid variables.$neutral-lighter-100;
        padding-left: 10px;

        &__item {
            margin-bottom: 10px;

            &__value {
                color: variables.$neutral-light;
            }

            &__time {
                color: variables.$neutral-light;
            }
        }

        &__text {
            margin-bottom: 10px;
            color: variables.$neutral-dark;
            font-weight: bold;
        }
    }
}

$voucher-row-height: 48px;
/* wide */
@media (min-width: variables.$responsive-break) {
    #voucher-code {
        width: 280px;
        height: $voucher-row-height;
    }

    .cart-item__footer {
        flex-direction: row-reverse;
        margin: 64px 0 64px 16px;
        justify-content: space-between;

        .price {
            font-size: variables.$font-size-3xl;
        }

        .cart-summary {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            color: variables.$neutral;

            & > * {
                margin-bottom: 8px;
            }

            .total-sum {
                color: variables.$black;
            }
        }

        .checkout-button {
            text-align: right;
        }

        strong {
            color: black;
        }
    }

    .voucher {
        align-items: center;
        gap: 15px;

        .voucher__button {
            height: $voucher-row-height;
        }

        .voucher__input {
            padding: 16px;
        }

        .accordion__title {
            font-weight: bold;
            justify-content: flex-start;
            height: 22px;
        }
    }
}

/* narrow */
@media (max-width: variables.$responsive-break - 1) {
    .footer {
        display: none;
    }
    .cart-item__footer {
        .button-checkout {
            display: none;
        }

        .two-col {
            display: flex;
            justify-content: space-between;
        }

        .price {
            font-size: variables.$font-size-md;
        }

        .cart-summary {
            display: none;
        }
    }

    .voucher {
        gap: 15px;
        margin: 0 15px 115px 15px;
        text-align: center;

        .accordion__title {
            font-weight: variables.$font-weight-normal;
            height: 20px;
            justify-content: center;
        }

        .accordion__item--active {
            width: 100%;

            & > .accordion__content {
                flex-direction: column;
                align-items: center;
            }
        }

        .voucher__input {
            padding: 16px;
        }
    }

    #voucher-code {
        width: initial;

    }
    #saved-products {
        background: variables.$white;
    }

    .checkout-shipping {
        &__info {
            &__item {
                display: flex;
                flex-direction: column;
            }
        }
    }
}
